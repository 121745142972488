













































import { Component, Vue, Watch } from 'vue-property-decorator';
import MonthPicker from '@/components/form/MonthPicker.vue';
import ErrorAlert from '@/components/ErrorAlert.vue';
import ConservatorSelect from '@/modules/conservator/components/ConservatorSelect.vue';

@Component({
  components: {
    ConservatorSelect,
    ErrorAlert,
    MonthPicker
  }
})
export default class ConservatorshipListExportDialog extends Vue {
  dialog: boolean = false;
  loading: boolean = false;
  error: any = null;

  file: string | null = null;
  format: string | null = null;
  conservator: string | null = null;

  get download(): string {
    return `betreutenliste.${this.format}`;
  }

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) return;

    setTimeout(() => {
      this.error = null;
      this.file = null;
      this.format = null;
      this.loading = false;
    }, 200);
  }

  async save(format: 'csv' | 'pdf') {
    this.error = null;

    this.loading = true;

    try {
      const response = await this.$http.get(`api/conservatorship/${format}`, { responseType: 'blob', params: { 'conservator_id': this.conservator } });

      this.loading = false;
      this.format = format;
      this.file = URL.createObjectURL(response.data);
    } catch (error) {
      this.error = error;
      this.loading = false;
    }
  }
}
